<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-3 m-3 c-12">
                    <comment :objectType="37" :objectId="this.$route.params.id" />
                </div>
                <div class="col l-9 m-9 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Dự toán chi phí làm hàng</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="page-list-action"> 
                                    <md-button v-shortkey="['ctrl', 'i']" @shortkey="print()" @click="print()" class="md-raised md-view btn-add"><span>I</span>n<md-tooltip>In đề nghị (Ctrl + I)</md-tooltip></md-button>
                                    <router-link v-if="entity.invoiceId == 0 && entity.status == 2" :to="'/repair-invoice/crud?est=' + entity.id">
                                        <md-button v-if="entity.invoiceId == 0" v-shortkey="['ctrl', 'q']" class="md-raised md-view btn-add"><span>Q</span>uyết toán<md-tooltip>Quyết toán (Ctrl + Q)</md-tooltip></md-button>
                                    </router-link>
                                    <md-button v-if="entity.status == 2 && entity.invoiceId > 0" v-shortkey="['ctrl', 'e']" @shortkey="viewInvoice()" @click="viewInvoice()" class="md-raised md-view btn-add">X<span>e</span>m hóa đơn<md-tooltip>Xem hóa đơn (Ctrl + E)</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <div class="wrapper">
                                <div class="row no-gutters">
                                    <div class="col l-4 m-4 c-12">
                                        <div class="left">
                                            <img src="../../../assets/images/logo.png" />
                                        </div>
                                    </div>
                                    <div class="col l-8 m-8 c-12">
                                        <div class="center">
                                            <div class="title"><h2>DỰ TOÁN CHI PHÍ LÀM HÀNG</h2></div>
                                            <div class="code"><span class="label">Số: </span><span>{{entity.estimateCode}}</span></div>
                                            <div class="date"><span class="label">Ngày: </span><span>{{entity.estimateDate}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters" style="padding-top:10px;">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="line"> 
                                            <label>Người lập phiếu: </label>
                                            <div>{{entity.staff.fullName}}</div>
                                        </div>
                                        <div class="line-2">
                                            <div class="line"> 
                                                <label>Ghi chú: </label>
                                                <div>{{entity.note}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row no-gutters">
                                    <div class="col l-12 m-12 c-12" style="padding-top:20px;">
                                        <table class="table-1">
                                            <thead>
                                                <tr>
                                                    <th style="width:80px;">STT</th>
                                                    <th style="width:400px;">Số con't/Kiện hàng</th>
                                                    <th style="width:120px;">Loại</th>
                                                    <th style="width:150px;">Đơn giá</th>
                                                    <th style="width:100px;">Vat</th>
                                                    <th style="width:150px;">Thành tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in details" :key="'item-' + index">
                                                <tr>
                                                    <td style="text-align: center; padding: 5px 10px; border: 1px solid #e0e0e0;"><span style="font-weight:bold;">{{romanize(index + 1)}}</span></td>
                                                    <td style="padding: 5px 10px; border: 1px solid #e0e0e0;">
                                                        <span v-if="item.key !== ''" style="font-weight:bold;">{{item.key}}</span>
                                                        <span v-else style="font-weight:bold;">Phí làm hàng</span>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr v-for="(obj, i) in item.list" :key="'detail' + i">
                                                    <td class="stt">{{i + 1}}</td>
                                                    <td style="padding-left: 30px;">{{obj.fee.feeName}}</td>
                                                    <td>{{ getFeeType(obj.type) }}</td>
                                                    <td style="text-align: right;">{{formatNumber(obj.price)}}</td>
                                                    <td style="text-align: center;">{{formatNumber(obj.vat)}}%</td>
                                                    <td style="text-align: right;">{{formatNumber(obj.amount)}}</td>
                                                </tr>
                                            </tbody>
                                            
                                            <tbody>
                                                <tr>
                                                    <td colspan="5" class="stt"><b>Tổng cộng</b></td>
                                                    <td style="text-align: right; font-weight: bold;">{{ formatNumber(entity.pay) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="approve"> 
                                            <div v-for="item in entity.approveList" :key="item.id" class="approve-item">
                                                <div class="title">{{item.title}}</div>
                                                <div class="approve-status">
                                                    <md-chip v-if="item.status == 1" class="md-wait">Chờ duyệt</md-chip>
                                                    <md-chip v-else-if="item.status == 2" class="md-primary"><i class="uil uil-check"></i> Đã duyệt</md-chip>
                                                    <md-chip v-else-if="item.status == 3" class="md-status-2">Yêu cầu chỉnh sửa</md-chip>
                                                    <md-chip v-else-if="item.status == 4" class="md-accent">Không phê duyệt</md-chip>
                                                </div>
                                                <div class="name">{{item.staff.fullName}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="action">
                                    <md-button :disabled="entity.status != 1"  v-shortkey="['ctrl', 'p']" @shortkey="showConfirmApprove()" @click="showConfirmApprove()" class="md-raised md-primary"><span>P</span>hê duyệt<md-tooltip>Phê duyệt (Ctrl + P)</md-tooltip></md-button>
                                    <md-button :disabled="entity.status != 1" v-shortkey="['ctrl', 'k']" @shortkey="showConfirmReject()" @click="showConfirmReject()" class="md-raised md-accent"><span>K</span>hông phê duyệt<md-tooltip>Không phê duyệt (Ctrl + K)</md-tooltip></md-button>
                                    <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <md-dialog :md-active.sync="showApproveDialog">
            <md-dialog-title>Bạn có chắc chắn PHÊ DUYỆT?</md-dialog-title>
            <md-content>
                <div style="font-weight:bold;padding-bottom:10px;">Phê duyệt Dự toán chi phí làm hàng - Số: {{entity.estimateCode}} - Ngày: {{entity.estimateDate}}</div>
                <md-field>
                    <md-textarea name="note" v-model="note" md-counter="500"></md-textarea>
                </md-field>
            </md-content>
            <md-dialog-actions>
                <md-button @click="showApproveDialog = false">Đóng</md-button>
                <md-button class="md-primary" @click="onApproved()">Gửi phê duyệt</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-active.sync="showRejectDialog">
            <md-dialog-title>Bạn có chắc chắn KHÔNG PHÊ DUYỆT?</md-dialog-title>
            <md-content>
                <div style="font-weight:bold;padding-bottom:10px;">Phê duyệt Dự toán chi phí làm hàng - Số: {{entity.estimateCode}} - Ngày: {{entity.estimateDate}}</div>
                <md-field :class="{'md-invalid': submitted && $v.note.$error }">
                    <md-textarea name="note" v-model="note" md-counter="500"></md-textarea>
                    <span class="md-error" v-if="submitted && !$v.note.required">Vui lòng nhập ý kiến </span>
                </md-field>
            </md-content>
            <md-dialog-actions>
                <md-button @click="showRejectDialog = false">Đóng</md-button>
                <md-button class="md-primary" @click="onReject()">Gửi không phê duyệt</md-button>
            </md-dialog-actions>
        </md-dialog>
   </div>
</template>

<script>
    import orderEstimateService from '../../../api/orderEstimateService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import { required } from 'vuelidate/lib/validators';
    import comment from '../../../components/Comment.vue';

    export default ({
        components: {
            comment
        },
        metaInfo: {
            title: 'Dự toán chi phí làm hàng'
        },
        data() {
            return {
                title: 'Dự toán chi phí làm hàng',
                id: 0,
                submitted: false,
                loadding: false,
                details: [],
                obj: {},
                note: '',
                entity: { id: 0, staff: { fullName: '' }, details: [], approveList: [] },
                showApproveDialog: false,
                showRejectDialog: false
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            showConfirmApprove(){
                this.note = "";
                this.showApproveDialog = true;
            },

            showConfirmReject(){
                this.note = "";
                this.showRejectDialog = true;
            },

            onCancel () {
                console.log('Cancel');
            },

            onApproved() {
                this.status = 2;
                this.submit();
                this.showApproveDialog = false;
            },

            onReject() {
                this.status = 4;
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.submit();
                this.showConfirmReject = false;
            },

            submit(){
                this.setLoading(true);
                const data = { object: this.entity, status: this.status, note: this.note }
                orderEstimateService.submitApprove(data).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/order-estimate/approve-list');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); this.showRejectDialog = false });
            },

            viewInvoice(){
                let route = this.$router.resolve('/order-estimate/view/' + this.entity.invoiceId);
                window.open(route.href, '_blank');
            },

            submitInvoice(){
                this.$refs.invoiceForm.open(this.entity);
            },

            getById(){
                this.setLoading(true);
                orderEstimateService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;

                        const details = this.groupBy(this.entity.details);
                        this.details = [];

                        Object.keys(details).forEach((item, index) => {
                            const objItem = { key: item, list: details[item] };
                            details[index] = details[item];
                            delete details[item];
                            this.details.push(objItem);
                        });
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            groupBy(list) {
                const groups = list.reduce((groups, item) => ({
                    ...groups,
                    [item.estimate.estimateCode]: [...(groups[item.estimate.estimateCode] || []), item]
                }), {});
                return groups;
            },

            romanize(num) {
                if (isNaN(num))
                    return NaN;
                var digits = String(+num).split(""),
                    key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                        "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                        "","I","II","III","IV","V","VI","VII","VIII","IX"],
                    roman = "",
                    i = 3;
                while (i--)
                    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                return Array(+digits.join("") + 1).join("M") + roman;
            },

            back(){
                this.$router.push('/order-estimate/approve-list');
            }
        },
        validations: {
            note: { required },
        }
    })

</script>

<style scoped>
    table.table-1 input {
        height: 30px;
        width: 100%;
        border: 1px solid #e0e0e0;
        padding: 0 5px;
    }
    .md-checkbox {
        margin: 0 !important;
    }
    .md-content {
        padding: 0 15px 0px 15px;
        width: 550px;
        height: 455px;
        max-width: 550px;
        max-height: 150px;
    }
    .dialog-content {
        height: 150px;
    }
    .action {
        position: sticky;
        bottom: 20px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
    }
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 26px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 18px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 18px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 18px;
    }
    .line span{
        font-size: 18px;
        font-weight: bold;
    }
    .line div {
        font-size: 18px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 14px;
    }
    .table-1 {
        min-height: 140px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 17px;
    }
</style>
